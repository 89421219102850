import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../store';
import { KeyValuePair } from '../models/Common';
import { Box, Paragraph, Heading, Text, Button, TextArea, Card, CardBody, CardHeader, Image, Anchor, Header, Layer } from 'grommet';
import { Hpe, LinkNext, FormClose } from 'grommet-icons';
import { retrieveLocalizedStrings, siteSlice } from '../store/Site';
import BasePage from '../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';
import { push, replace } from 'connected-react-router';
import { formatString } from '../Utilities';

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    push,
    replace,
    ...siteSlice.actions,
}

interface IMasterAseAdvantageProps {
}

interface IMasterAseAdvantageState {
    showVideo: boolean;
}

type MasterAseAdvantageProps = IMasterAseAdvantageProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class MasterAseAdvantage extends React.PureComponent<MasterAseAdvantageProps, IMasterAseAdvantageState> {

    constructor(props: MasterAseAdvantageProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.MasterAseAdvantage_Keywords', 'Metadata.MasterAseAdvantage_Description',
            'ExternalPages.MasterAseAdvantage_Instro_Statement', 'ExternalPages.MasterAseAdvantage_Benefit_Label', 'ExternalPages.MasterAseAdvantage_PurchaseNow_Label',
            'ExternalPages.MasterAseAdvantage_ProgramFeatures_Card1_Title', 'ExternalPages.MasterAseAdvantage_ProgramFeatures_Card1_Statement',
            'ExternalPages.MasterAseAdvantage_ProgramFeatures_Card2_Title', 'ExternalPages.MasterAseAdvantage_ProgramFeatures_Card2_Statement',
            'ExternalPages.MasterAseAdvantage_ProgramFeatures_Card3_Title', 'ExternalPages.MasterAseAdvantage_ProgramFeatures_Card3_Statement',
            'ExternalPages.MasterAseAdvantage_ProgramFeatures_Label', 'ExternalPages.MasterAseAdvantage_ProgramFeatures_Presentation_Label',
            'ExternalPages.MasterAseAdvantage_ProgramFeatures_FAQ_Label', 'ExternalPages.MasterAseAdvantage_Values_Label',
            'ExternalPages.MasterAseAdvantage_Values_Card1_Title', 'ExternalPages.MasterAseAdvantage_Values_Card2_Title',
            'ExternalPages.MasterAseAdvantage_Values_Card3_Title', 'ExternalPages.MasterAseAdvantage_Values_Card1_Statement',
            'ExternalPages.MasterAseAdvantage_Values_Card2_Statement', 'ExternalPages.MasterAseAdvantage_Values_Card3_Statement',
            'ExternalPages.MasterAseAdvantage_JoinTechPro_Statement', 'ExternalPages.MasterAseAdvantage_Intro_Statement',
            'ExternalPages.MasterAseAdvantage_ProgramFeatures_Card1_Btn_Label', 'ExternalPages.MasterAseAdvantage_MembershipRequired_Label',
            'ExternalPages.MasterAseAdvantage_ProgramFeatures_Card3_Btn_Label', 'ExternalPages.MasterAseAdvantage_SpecialUpgradePackage_Statement'];

        this.state = {
            showVideo: false
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setActiveMenu('communities-programs-menu');
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    onCloseVideo = () => {
        this.setState({ showVideo: false });
    }

    onShowVideo = () => {
        this.setState({ showVideo: true });
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let page = site.menuStrings['ExternalPages.MasterAseAdvantage_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['ExternalPages.Navigation_CommunitiesPrograms'], value: "" },
            { key: page, value: "" }
        ];

        return <BasePage breadcrumbs={breadcrumbs} title={page} pageName="MasterAseAdvantage" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.MasterAseAdvantage_Keywords']}
            metadataDescription={site.localizedStrings['Metadata.MasterAseAdvantage_Description']} >
            {site.stringsLoaded && <Box pad={{ bottom: 'small' }}>
                <Box direction="row-responsive" gap="large" pad="medium">
                    <Box gap="small" alignSelf="center" fill="horizontal">
                        <Text weight="bold">{site.localizedStrings['ExternalPages.MasterAseAdvantage_Benefit_Label']}</Text>
                        <Heading level="3">{page}</Heading>
                        <Paragraph fill>
                            {parse(site.localizedStrings['ExternalPages.MasterAseAdvantage_Intro_Statement'])}
                        </Paragraph>
                        <Box gap="small" alignSelf="start">
                            <Box direction="row-responsive">
                                <Button primary href="https://techpro.hpe.com/custom.aspx?page=MASE_ADVANTAGE" target="_blank"
                                    label={<Box direction="row-responsive" gap="xsmall" align="baseline" alignContent="end">
                                        <Text>{site.localizedStrings['ExternalPages.MasterAseAdvantage_PurchaseNow_Label']}</Text>
                                        <Text size="xsmall" alignSelf="baseline">{site.localizedStrings['ExternalPages.MasterAseAdvantage_MembershipRequired_Label']}</Text>
                                    </Box>} />
                            </Box>
                            <Anchor href="https://techpro.hpe.com/about.aspx" target="_blank"
                                label={<Box direction="row" gap="xxsmall" align="baseline">
                                    <Text>{site.localizedStrings['ExternalPages.MasterAseAdvantage_JoinTechPro_Statement']}</Text>
                                    <LinkNext size="small" />
                                </Box>} />
                            <Anchor href="https://www.hpe.com/psnow/doc/a00140922enw" target="_blank"
                                label={<Box direction="row" gap="xxsmall" align="baseline">
                                    <Text>{site.localizedStrings['ExternalPages.MasterAseAdvantage_SpecialUpgradePackage_Statement']}</Text>
                                    <LinkNext size="small" />
                                </Box>} />
                        </Box>
                    </Box>
                    <Box fill hoverIndicator onClick={() => { this.setState({ showVideo: true }) }}>
                        <Image fit="contain" src="images/master-ase-advantage/VideoImage.png" />
                    </Box>
                </Box>
                <Box gap="medium" background={{ size: "fit", color: "#F4F4F3" }} pad={{ top: "medium", bottom: "large", left: "large", right: "large" }}>
                    <Text size="3xl">{site.localizedStrings['ExternalPages.MasterAseAdvantage_ProgramFeatures_Label']}</Text>
                    <Box direction="row-responsive" gap="large" justify="between" pad={{ top: "medium" }}>
                        <Card width="large" pad="medium" >
                            <CardBody gap="small">
                                <Heading level={4}>{site.localizedStrings['ExternalPages.MasterAseAdvantage_ProgramFeatures_Card1_Title']}</Heading>
                                <Paragraph>
                                    {parse(site.localizedStrings['ExternalPages.MasterAseAdvantage_ProgramFeatures_Card1_Statement'])}
                                </Paragraph>
                                <Box direction="row">
                                    <Box direction="row" round hoverIndicator align="baseline" gap="xxsmall"
                                        pad={{ top: "xsmall", left: "small", bottom: "xsmall", right: "small" }}
                                        border={{ side: "all", color: "brand", size: "small" }}
                                        onClick={() => { window.open("https://www.hpe.com/psnow/doc/a00143275enw", "_blank"); }}>
                                        <Text weight="bold">
                                            {site.localizedStrings['ExternalPages.MasterAseAdvantage_ProgramFeatures_Card1_Btn_Label']}
                                        </Text>
                                        <LinkNext size="small" />
                                    </Box>
                                </Box>
                            </CardBody>
                        </Card>
                        <Card width="large" pad="medium" >
                            <CardBody gap="small">
                                <Heading level={4}>{site.localizedStrings['ExternalPages.MasterAseAdvantage_ProgramFeatures_Card2_Title']}</Heading>
                                <Paragraph>
                                    {parse(site.localizedStrings['ExternalPages.MasterAseAdvantage_ProgramFeatures_Card2_Statement'])}
                                </Paragraph>
                            </CardBody>
                        </Card>
                        <Card width="large" pad="medium" >
                            <CardBody gap="small">
                                <Heading level={4}>{site.localizedStrings['ExternalPages.MasterAseAdvantage_ProgramFeatures_Card3_Title']}</Heading>
                                <Paragraph>
                                    {parse(site.localizedStrings['ExternalPages.MasterAseAdvantage_ProgramFeatures_Card3_Statement'])}
                                </Paragraph>
                                <Box direction="row">
                                    <Box direction="row" round hoverIndicator align="baseline" gap="xxsmall"
                                        pad={{ top: "xsmall", left: "small", bottom: "xsmall", right: "small" }}
                                        border={{ side: "all", color: "brand", size: "small" }}
                                        onClick={() => { window.open("https://www.hpe.com/psnow/doc/a00119738enw", "_blank"); }}>
                                        <Text weight="bold">
                                            {site.localizedStrings['ExternalPages.MasterAseAdvantage_ProgramFeatures_Card3_Btn_Label']}
                                        </Text>
                                        <LinkNext size="small" />
                                    </Box>
                                </Box>
                            </CardBody>
                        </Card>
                    </Box>
                    <Box direction="row-responsive" gap="medium" justify='center' align="center">
                        <Button primary href="https://www.hpe.com/psnow/doc/a00127863eew" target="_blank"
                            label={site.localizedStrings['ExternalPages.MasterAseAdvantage_ProgramFeatures_Presentation_Label']} />
                        <Anchor href={langInfo[0] === 'ja' ? "https://www.hpe.com/psnow/doc/a00119738jpn" : "https://www.hpe.com/psnow/doc/a00119738enw"}
                            target="_blank" label={<Box direction="row" gap="xxsmall" justify='center' align="baseline">
                                <Text>{site.localizedStrings['ExternalPages.MasterAseAdvantage_ProgramFeatures_FAQ_Label']}</Text>
                                <LinkNext size="small" color="brand" />
                            </Box>} />
                    </Box>
                </Box>
                <Box gap="medium" pad={{ top: "medium", bottom: "large", left: "large", right: "large" }}
                    background={{ size: "cover", image: "url('images/master-ase-advantage/HPE_data_slice_ymp_02_1600_0_72_RGB.jpg')" }}>
                    <Text size="3xl">{site.localizedStrings['ExternalPages.MasterAseAdvantage_Values_Label']}</Text>
                    <Box direction="row-responsive" gap="large" justify="between" pad={{ top: "medium" }}>
                        <Card width="large" pad="medium" >
                            <CardBody gap="small">
                                <Heading level={4}>{site.localizedStrings['ExternalPages.MasterAseAdvantage_Values_Card1_Title']}</Heading>
                                <Paragraph>
                                    {parse(site.localizedStrings['ExternalPages.MasterAseAdvantage_Values_Card1_Statement'])}
                                </Paragraph>

                            </CardBody>
                        </Card>
                        <Card width="large" pad="medium" >
                            <CardBody gap="small">
                                <Heading level={4}>{site.localizedStrings['ExternalPages.MasterAseAdvantage_Values_Card2_Title']}</Heading>
                                <Paragraph>
                                    {parse(site.localizedStrings['ExternalPages.MasterAseAdvantage_Values_Card2_Statement'])}
                                </Paragraph>
                            </CardBody>
                        </Card>
                        <Card width="large" pad="medium" >
                            <CardBody gap="small">
                                <Heading level={4}>{site.localizedStrings['ExternalPages.MasterAseAdvantage_Values_Card3_Title']}</Heading>
                                <Paragraph>
                                    {parse(formatString(site.localizedStrings['ExternalPages.MasterAseAdvantage_Values_Card3_Statement'],
                                        [`${localUrl}/certification-distinction`]))}
                                </Paragraph>
                            </CardBody>
                        </Card>
                    </Box>
                </Box>
                {this.state.showVideo && <Layer>
                    <Header justify='between' background="brand">
                        <Button alignSelf='end' icon={<FormClose />} onClick={this.onCloseVideo} />
                    </Header>
                    <Box height='large' width='xlarge'>
                        <iframe width="100%" height="100%" src='https://www.youtube.com/embed/cUuX6B3XUJE?autoplay=1'
                            frameBorder="0" allowFullScreen title={page}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
                    </Box>
                </Layer>}
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(MasterAseAdvantage as any));
